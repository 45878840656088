import {
  TIME_REPORT_FORM_RETRIEVE_TIME_REPORT,
  TIME_REPORT_FORM_SET_CONTRACT,
  TIME_REPORT_FORM_SET_DEVELOPER,
  TIME_REPORT_FORM_SET_TIME_REPORT,
  TIME_REPORT_FORM_SEND_TIME_REPORT,
  TIME_REPORT_FORM_DELETE_TIME_REPORT,
  TIME_REPORT_FORM_SET_RETRIEVE_TIME_REPORT_ERRORS,
  TIME_REPORT_FORM_SET_SEND_TIME_REPORT_ERRORS,
  TIME_REPORT_FORM_RESET_TIME_REPORT,
  TIME_REPORT_FORM_RESET_CONTRACT,
  TIME_REPORT_FORM_RESET_RETRIEVE_TIME_REPORT_ERRORS,
  TIME_REPORT_FORM_RESET_SEND_TIME_REPORT_ERRORS
} from "../actionTypes";
import moment from "moment";
import {isCorrectMinutes, isCorrectPositiveDecimal, isInteger} from "helpers";

const initialState = {
  timeReport: {
    id: 0,
    day_worked_on: moment().format("YYYY-MM-DD"),
    hours: "",
    minutes: "",
    client_hours: "",
    client_minutes: "",
    client_hours_manually_changed: false
  },
  contract: {
    id: 0,
    name: "",
    status: "active"
  },
  developer: {
    uid: 0,
    name: ""
  },
  fetching: false,
  sending: false,
  retrieveErrors: null,
  sendErrors: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TIME_REPORT_FORM_SET_TIME_REPORT:
      if (action.payload.timeReport.id === 0) { // check if it is a new timeReportForm
        if (
          action.payload.timeReport.hours !== "" &&
          !isInteger(action.payload.timeReport.hours)
        ) {
          action.payload.timeReport.hours = state.timeReport.hours;
        }
        if (
          action.payload.timeReport.minutes !== "" &&
          !isCorrectMinutes(action.payload.timeReport.minutes)
        ) {
          action.payload.timeReport.minutes = state.timeReport.minutes;
        }
        if (
          state.timeReport.hours !== action.payload.timeReport.hours &&
          state.timeReport.hours === state.timeReport.client_hours
        ) {
          action.payload.timeReport.client_hours = action.payload.timeReport.hours;
        }
        if (
          state.timeReport.minutes !== action.payload.timeReport.minutes &&
          state.timeReport.minutes === state.timeReport.client_minutes
        ) {
          action.payload.timeReport.client_minutes = action.payload.timeReport.minutes;
        }

      } else if (!isCorrectPositiveDecimal(action.payload.timeReport.hours)) {
        action.payload.timeReport.hours = state.timeReport.hours;
      } else {
        if (
          !action.payload.timeReport.client_hours_manually_changed &&
          state.timeReport.hours !== action.payload.timeReport.hours &&
          state.timeReport.hours === state.timeReport.client_hours
        ) {
          action.payload.timeReport.client_hours = action.payload.timeReport.hours;
        }
      }
      return {
        ...state,
        timeReport: (({ id, day_worked_on, hours, minutes, client_hours, client_minutes, client_hours_manually_changed }) => ({
          id,
          day_worked_on,
          hours,
          minutes,
          client_hours,
          client_minutes,
          client_hours_manually_changed
        }))(action.payload.timeReport),
        fetching: false,
        sending: false
      };
    case TIME_REPORT_FORM_SET_CONTRACT:
      return {
        ...state,
        contract: (({ id, name, status }) => ({
          id,
          name,
          status
        }))(action.payload.contract)
      };
    case TIME_REPORT_FORM_SET_DEVELOPER:
      return {
        ...state,
        developer: (({ uid, name }) => ({
          uid,
          name
        }))(action.payload.developer)
      };
    case TIME_REPORT_FORM_RETRIEVE_TIME_REPORT:
      return { ...state, fetching: true, retrieveErrors: null };
    case TIME_REPORT_FORM_SEND_TIME_REPORT:
    case TIME_REPORT_FORM_DELETE_TIME_REPORT:
      return { ...state, sending: true, sendErrors: null };
    case TIME_REPORT_FORM_SET_RETRIEVE_TIME_REPORT_ERRORS:
      return {
        ...state,
        retrieveErrors: Array.isArray(action.payload.errors)
          ? [...action.payload.errors]
          : { ...action.payload.errors },
        fetching: false,
        sending: false
      };
    case TIME_REPORT_FORM_SET_SEND_TIME_REPORT_ERRORS:
      return {
        ...state,
        sendErrors: Array.isArray(action.payload.errors)
          ? [...action.payload.errors]
          : { ...action.payload.errors },
        fetching: false,
        sending: false
      };
    case TIME_REPORT_FORM_RESET_TIME_REPORT:
      return {
        ...initialState,
        timeReport: { ...initialState.timeReport },
        contract: { ...initialState.contract },
        developer: { ...initialState.developer }
      };
    case TIME_REPORT_FORM_RESET_CONTRACT:
      return {
        ...state,
        contract: { ...initialState.contract }
      };
    case TIME_REPORT_FORM_RESET_RETRIEVE_TIME_REPORT_ERRORS:
      return { ...state, retrieveErrors: null };
    case TIME_REPORT_FORM_RESET_SEND_TIME_REPORT_ERRORS:
      return { ...state, sendErrors: null };
    default:
      return state;
  }
}
