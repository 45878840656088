import {
  DAILY_TIME_REPORTS_RETRIEVE_TIME_REPORTS,
  DAILY_TIME_REPORTS_SET_TIME_REPORTS,
  DAILY_TIME_REPORTS_SET_PARAMS,
  DAILY_TIME_REPORTS_SET_FILTERS,
  DAILY_TIME_REPORTS_SET_SORTING,
  DAILY_TIME_REPORTS_SET_PAGER,
  DAILY_TIME_REPORTS_RESET_PARAMS,
  DAILY_TIME_REPORTS_RESET_FILTERS,
  DAILY_TIME_REPORTS_RESET_SORTING,
  DAILY_TIME_REPORTS_RESET_PAGER
} from "../actionTypes";
import { getMondayDate } from "../../helpers";
import {
  DATE_FORMAT,
  DATE_FORMAT_DAILY_TIME_REPORTS
} from "../../variables/general";
import moment from "moment";

const initialState = {
  list: [],
  fetching: false,
  params: {
    accountManagerId: 0,
    startDate: getMondayDate().format(DATE_FORMAT),
    endDate: moment().format(DATE_FORMAT)
  },
  filters: [],
  sorting: [
    { columnName: "day_worked_on", direction: "desc" },
    { columnName: "developer_name", direction: "asc" }
  ],
  pager: {
    daysTotal: 0,
    currentPage: 1,
    daysPerPage: 10
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DAILY_TIME_REPORTS_SET_TIME_REPORTS:
      return {
        ...state,
        list: action.payload.timeReports.map(timeReport => ({
          id: timeReport.id,
          contract_name: timeReport.contract.name,
          client_name:
            (timeReport.contract.project &&
              timeReport.contract.project.client) ||
            timeReport.contract.client,
          project_id:
            timeReport.contract.project_id ||
            (timeReport.contract.project && timeReport.contract.project.id),
          developer_name: timeReport.contract.developer.name,
          developer_uid: timeReport.contract.developer.uid,
          day_worked_on: moment(timeReport.day_worked_on).format(
            DATE_FORMAT_DAILY_TIME_REPORTS
          ),
          hours: +timeReport.hours,
          client_hours: +timeReport.client_hours,
          client_hours_manually_changed: timeReport.client_hours_manually_changed
        })),
        fetching: false
      };
    case DAILY_TIME_REPORTS_RETRIEVE_TIME_REPORTS:
      return { ...state, fetching: true };
    case DAILY_TIME_REPORTS_SET_PARAMS:
      return { ...state, params: { ...action.payload.params } };
    case DAILY_TIME_REPORTS_SET_FILTERS:
      return { ...state, filters: [...action.payload.filters] };
    case DAILY_TIME_REPORTS_SET_SORTING:
      return { ...state, sorting: [...action.payload.sorting] };
    case DAILY_TIME_REPORTS_SET_PAGER:
      return { ...state, pager: { ...action.payload.pager } };
    case DAILY_TIME_REPORTS_RESET_PARAMS:
      return { ...state, params: { ...initialState.params } };
    case DAILY_TIME_REPORTS_RESET_FILTERS:
      return { ...state, filters: [...initialState.filters] };
    case DAILY_TIME_REPORTS_RESET_SORTING:
      return { ...state, sorting: [...initialState.sorting] };
    case DAILY_TIME_REPORTS_RESET_PAGER:
      return { ...state, pager: { ...initialState.pager } };
    default:
      return state;
  }
}
